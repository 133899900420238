import React from 'react';

export const Cards = props => {
  return props.red
    ? (
      <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="0.5" y="0.5" width="9" height="15" rx="0.5" fill="#E43F49" stroke="white"/>
      </svg>
    )
    : (
      <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="0.5" y="0.5" width="9" height="15" rx="0.5" fill="#FFD12F" stroke="white"/>
      </svg>
    );
};
